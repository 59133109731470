import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const HistoryScoreSummary = ({ device }) => {
  return (
    <>
      <div className="score-list section ">
        <div className="card z-depth-0 speednavgrey">
          <div className="card-content white-text text-darken-3">
            <div className="row">
              <div className="col s4  left-align">
                <Link
                  to={"/score/" + device.device_id + "/fullscore"}
                  key={device.device_id}
                >
                  <a className="btn-small backgroundcolor">
                    <i class="material-icons">aspect_ratio</i>
                  </a>
                </Link>
              </div>

              <div className="col s4">
                <span className="card-title center-align red-text biggerscore">
                  {device.speed}
                </span>

                <h6 className="center-align">km/h</h6>
              </div>
              <div className="col s4 right-align">
                <Link
                  to={"/score/" + device.device_id}
                  key={device.createdAt}
                  device={device}
                >
                  <a className="btn-small backgroundcolor">
                    <i class="material-icons">forward</i>
                  </a>
                </Link>
              </div>
              <div className="col s6">
                <p>Device Id:{device.device_id}</p>
                <p className="orange-text">
                  {moment(device.createdAt.toDate()).calendar()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryScoreSummary;
