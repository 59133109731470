import React from "react";
import moment from "moment";

const HighScores = props => {
  const { notifications } = props;
  //let myArray = notifications && [...notifications];
  //let sortedActivities = null;
  // const sortedActivities =
  //   myArray && myArray.slice(0, 11).sort((a, b) => b.speed - a.speed);
  let myArray = notifications && notifications.map(item => item);
  const mySortedActivities =
    myArray && myArray.sort((a, b) => Number(b.speed) - Number(a.speed));
  var nbrTopScore = 6;
  const sortedActivities =
    mySortedActivities && mySortedActivities.slice(0, nbrTopScore);
  // const sortedActivities =
  //   myArray &&
  //   Object.values(myArray)
  //     .slice(0, 11)
  //     .sort(function(c, d) {
  //       return d["speed"] - c["speed"];
  //     });
  return (
    <div className="section">
      <div className="card z-depth-2 speednavgrey">
        <div className="card-content">
          <h6 className="card-title center-align orange-text">
            Top {nbrTopScore}'s
          </h6>
          <ul className="notification">
            {sortedActivities &&
              sortedActivities.map(item => (
                <li key={item.id}>
                  <span className="white-text">{item.speed} km/h</span>
                  <div className="white-text note-date">
                    <span>Device Id: {item.device_id}</span>
                  </div>

                  <div className="orange-text note-date">
                    {moment(item.createdAt.toDate()).fromNow()}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HighScores;
