import React from "react";
import moment from "moment";

const DeviceHighScores = props => {
  //const { currentNotification } = props;
  const { notifications } = props;
  const { currentId } = props;
  let myArray = notifications && notifications.map(item => item);
  const mySortedActivities =
    myArray &&
    myArray
      .filter(item => parseInt(item.device_id, 10) === parseInt(currentId, 10))
      .sort((a, b) => Number(b.speed) - Number(a.speed));
  const sortedActivities = mySortedActivities && mySortedActivities.slice(0, 5);
  return (

    <div className="section">
      <div className="card z-depth-2 speednavgrey">
        <div className="card-content">
          <h3
            className="card-title center-align orange-text"
            style={{ fontSize: 35 }}
          >
            TOP 5's
          </h3>
          <ul className="notification center-align">
            {sortedActivities &&
              sortedActivities.map(item => (
                <li key={item.id}>
                  <h4 className="white-text">{item.speed} km/h</h4>
                  {/* <div className="white-text note-date">
                    <span>Device Id: {item.device_id}</span>
                  </div> */}

                  <div className="orange-text note-date">
                    {moment(item.createdAt.toDate()).fromNow()}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DeviceHighScores;
