import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import DeviceDashboard from "./components/deviceDashboard/DeviceDashboard";
import LogIn from "./components/auth/LogIn";
import SignUp from "./components/auth/SignUp";
import CreateScore from "./components/scores/CreateScore";
import ScoreFullScreen from "./components/deviceScores/ScoreFullScreen";
import AllTimeHighScores from "./components/scores/AllTimeHighScores";
import AllTimeResults from "./components/scores/AllTimeResults";
import Footer from "./components/layout/Footer";
import ScoreList from "./components/scores/ScoreList";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/score/:id" component={DeviceDashboard} />
          <Route path="/mydevices" component={ScoreList} />
          <Route path="/login" component={LogIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/createScore" component={CreateScore} />
          <Route path="/allhighscores" component={AllTimeHighScores} />
          <Route path="/allresults" component={AllTimeResults} />
          <Route path="/score/:id/fullscore" component={ScoreFullScreen} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
