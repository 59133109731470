import React from "react";
import { Link } from "react-router-dom";
import speedshooting_logo from "./../../images/speedshooting_logo.png";

const footer = () => {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row">
          <div className="col s12 m4 l3 left-align">
            <h5 className="white-text">Contact us:</h5>
            <p className="white-text">Adress: Klostergatan 4, 22222, Lund</p>
            <p className="grey-text text-lighten-4">Telefon: 0702-72 26 61</p>
            <p className="grey-text text-lighten-4">
              Email: info@speedshooting.se
            </p>
          </div>
          <div className="col s12 m4 l3 ">
            <h5 className="white-text">Follow us:</h5>
            <ul>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://twitter.com/speedshootingSe"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://www.facebook.com/speedshooting.se/"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="grey-text text-lighten-3"
                  href="https://instagram.com/speedshooting.se"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="col s12 m4 l6 right-align">
            <Link to="/">
              <img src={speedshooting_logo} alt="speedshooting" />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container center">
          Copyright © Speedshooting.se 2020
        </div>
      </div>
    </footer>
  );
};

export default footer;
