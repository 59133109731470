import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class AllTimeResults extends Component {
  render() {
    const { notifications, auth } = this.props;
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    let sortedActivities = null;
    sortedActivities =
      notifications &&
      notifications.slice().sort((a, b) => b.createdAt - a.createdAt);

    return (
      <div className="section">
        <div className="card z-depth-0 speednavgrey">
          <div className="card-content">
            <span className="card-title center-align" style={{ fontSize: 35 }}>
              Alltime Results
            </span>
            <ul className="notification center-align">
              {sortedActivities &&
                sortedActivities.map(item => (
                  <li key={item.id}>
                    <span className="white-text" style={{ fontSize: 25 }}>
                      {item.speed} km/h
                    </span>
                    <div className="white-text note-date">
                      <span>Device Id: {item.device_id}</span>
                    </div>

                    <div className="orange-text note-date">
                      {moment(item.createdAt.toDate()).fromNow()}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [{ collection: "notifications" }])
)(AllTimeResults);
