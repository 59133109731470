const initState = props => ({
  scores: [
    { id: "1", score: "100", content: "bla bla" },
    { id: "2", score: "55", content: "bla bla" },
    { id: "3", score: "132", content: "bla bla" }
  ],
  
});

const scoreReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_SCORE":
      console.log("created score", action.score);
      return state;
    case "CREATE_SCORE_ERROR":
      console.log("create project error", action.err);
      return state;
    case "FILTER_BY_DEVICE_ID":
      //filter by device id
      //the value passed from our presentational component
      let value = action.payload.value;
      let filteredValues = state.firestore.ordered.notifications.filter(
        device => {
          //return any product whose name or designer contains the input box string
          return device.includes(value);
        }
      );
      return {
        ...state,
        notifications: filteredValues
      };
      return state;
    default:
      return state;
  }
};

export default scoreReducer;
