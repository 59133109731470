import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDCHUX6ViBCVZWa5fJZQ26cF6B3zRoXUKU",
  authDomain: "speedshootingapp.firebaseapp.com",
  databaseURL: "https://speedshootingapp.firebaseio.com",
  projectId: "speedshootingapp",
  storageBucket: "speedshootingapp.appspot.com",
  messagingSenderId: "368625598114",
  appId: "1:368625598114:web:8b10d5ed51336880c1a1fb",
  measurementId: "G-W4ELT7DEYW"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.firestore().settings({timestampsInSnapshots: true})

export default firebase;
