import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class SignedOutLinks extends Component {
  componentDidMount() {
    let M = window.M;
    var elems = document.querySelectorAll(".sidenav");
    var instances = M.Sidenav.init(elems, {
      edge: "right",
      draggable: true,
      inDuration: 250,
      outDuration: 200,
      onOpenStart: null,
      onOpenEnd: null,
      onCloseStart: null,
      onCloseEnd: null,
      preventScrolling: true
    });
  }
  render() {
    return (
      <>
        {/*Main menu*/}
        <ul className="right hide-on-med-and-down">
          <li>
            <NavLink to="/signup">Sign Up</NavLink>
          </li>
          <li>
            <NavLink to="/login">Log In</NavLink>
          </li>
        </ul>

        {/*Sidenav*/}
        <a href="#" data-target="mobile-demo" className="right sidenav-trigger">
          <i className="material-icons">menu</i>
        </a>
        <ul className="sidenav sidenav-close" id="mobile-demo">
          <li>
            <a href="#" className="right">
              <i class="material-icons">clear</i>
            </a>
          </li>
          <li>
            <NavLink to="/signup">Sign Up</NavLink>
          </li>
          <li>
            <NavLink to="/login">Log In</NavLink>
          </li>
        </ul>
      </>
    );
  }
}

export default SignedOutLinks;
