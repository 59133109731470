import React, { Component } from "react";
import ScoreSummary from "./ScoreSummary";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { render } from "react-dom";
import moment from "moment";
import * as Scroll from "react-scroll";

class ScoreList extends Component {
  // componentDidMount() {
  //   let M = window.M;
  //   var elems = document.querySelectorAll(".scrollspy");
  //   var instances = M.ScrollSpy.init(elems, {});
  // }

  handleOnChange = e => {
    if (e.target.value) {
      var scroller = Scroll.scroller;
      scroller.scrollTo(e.target.value, {
        duration: 1500,
        delay: 100,
        smooth: true,
        //containerId: 'ContainerElementID',
        offset: 0 // Scrolls to element + 50 pixels down the page
      });
    }
  };
  render() {
    const { speed_devices } = this.props;
    const sortedActivities =
      speed_devices &&
      speed_devices.slice().sort((a, b) => Number(a.id) - Number(b.id));
    var Scroll = require("react-scroll");
    var Element = Scroll.Element;
    var scroller = Scroll.scroller;

    return (
      <>
        <div className="dashboard container">
          
            <div className="row">
              <div className="col s2 offset-s5 input-field">
                <input
                  id="inputId"
                  type="text"
                  className="validate"
                  onChange={this.handleOnChange}
                />
                <label htmlFor="inputId">Search a Device id</label>
              </div>
            </div>
          
          <div className="score-list section">
            {sortedActivities &&
              sortedActivities.map(device => {
                return (
                  //<Link to={"/score/" + device.id} key={device.createdAt}>
                  //<ScoreSummary device={device} />
                  <Element name={device.device_id}>
                    <div className="score-list section">
                      <div className="card z-depth-2 speednavgrey">
                        <div className="card-content white-text text-darken-3">
                          <div className="row">
                            <div className="col s4  left-align">
                              <Link
                                to={"/score/" + device.device_id + "/fullscore"}
                                key={device.device_id}
                              >
                                <a className="btn-large backgroundcolor">
                                  <i className="material-icons">aspect_ratio</i>
                                </a>
                              </Link>
                            </div>

                            <div className="col s4">
                              <span className="card-title center-align red-text biggerscore">
                                {device.speed}
                              </span>

                              <h6 className="center-align">km/h</h6>
                            </div>
                            <div className="col s4 right-align">
                              <Link
                                to={"/score/" + device.device_id}
                                key={device.createdAt}
                                device={device}
                              >
                                <a className="btn-large backgroundcolor">
                                  <i className="material-icons">forward</i>
                                </a>
                              </Link>
                            </div>
                            <div className="col s6">
                              <p>Device Id:{device.device_id}</p>
                              <p className="orange-text">
                                {moment(device.createdAt.toDate()).calendar()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Element>
                  //</Link>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    speed_devices: state.firestore.ordered.speed_devices,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [{ collection: "speed_devices" }])
)(ScoreList);
