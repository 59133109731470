import React from "react";
import moment from "moment";

const DeviceScoreSummary = ({ device }) => {
  return (
    <div className="score-list section ">
      <div className="card z-depth-0 speednavgrey">
        <div className="card-content ">
          <span
            className="card-title center-align red-text text-darken-3"
            style={{ fontSize: 75 }}
          >
            {device.speed}
          </span>
          <p className="orange-text center-align">km/h</p>
          {/* <p className="white-text">Device Id:{device.device_id}</p> */}
          <p className="orange-text">
            {moment(device.createdAt.toDate()).calendar()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeviceScoreSummary;
