import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { signOut } from "../../store/actions/authAction";
import { Search } from "./Search";
import AllDropDown from "./AllDropDown";

class SignedInLinks extends Component {
  componentDidMount() {
    let M = window.M;
    var elems = document.querySelectorAll(".sidenav");
    //   var instances = M.Sidenav.init(elems, {});

    var instances = M.Sidenav.init(elems, {
      edge: "right",
      draggable: true,
      inDuration: 250,
      outDuration: 200,
      onOpenStart: null,
      onOpenEnd: null,
      onCloseStart: null,
      onCloseEnd: null,
      preventScrolling: true
    });
  }

  render() {
    // As in SignIn.jsx we need to use a function that gets as an argument firebase object
    const handleSignOut = () => {
      const { firebase } = this.props;
      this.props.signOut(firebase);
    };

    //const { profile } = props
    return (
      <>
        {/*Main menu*/}
        <ul className="right hide-on-med-and-down">
          {/* <form className="nopadding">
        <div className="input-field">
          <input id="search" type="search" required />
          <label className="label-icon" htmlFor="search">
            <i className="material-icons">search</i>
          </label>
          <i classNAme="material-icons">close</i>
        </div>
      </form> */}
          {/* <li>
          <form className="nopadding">
            <div className="input-field">
              <input type="search" placeholder="Search a Device" required />
            </div>
          </form>
        </li> */}
          {/* <li>
            <form className="nopadding">
              <div className="input-field">
                <input
                  id="search"
                  type="search"
                  placeholder="Search a Device"
                  required
                />
              </div>
            </form>
          </li> */}
          {/*
            <li>
              <NavLink to="/allhighscores">Alltime high scores</NavLink>
            </li>
          */}
          {/* <li>
        <a className="dropdown-trigger" href="#!" data-target="dropdown1">
          Find Device<i className="material-icons right">arrow_drop_down</i>
        </a>
      </li> */}
          {/*
            <li>
              <NavLink to="/createScore">Create Score</NavLink>
            </li>
          */}
          {/*  <li>
        <Search />
      </li> */}
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          {/* <li>
          <AllDropDown />
        </li> */}
          <li>
            <NavLink to="/mydevices">My Devices</NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={handleSignOut}>
              Log Out
            </NavLink>
          </li>{" "}
          {/* I left NavLink instead of anchor tag because I'm using airbnb eslint rules */}
          {/* <li>
        <NavLink to="/" className="btn btn-floating orange darken-4">
          {profile.initials}
        </NavLink>
      </li> */}
        </ul>

        {/*Sidenav*/}
        <a href="#" data-target="mobile-demo" className="right sidenav-trigger">
          <i className="material-icons">menu</i>
        </a>
        <ul className="sidenav sidenav-close" id="mobile-demo">
          <li>
            <a href="#" className="right">
              <i class="material-icons">clear</i>
            </a>
          </li>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/mydevices">My Devices</NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={handleSignOut}>
              Log Out
            </NavLink>
          </li>
        </ul>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signOut: (firebase) => dispatch(signOut(firebase))
});

export default compose(
  firebaseConnect(),
  connect(null, mapDispatchToProps)
)(SignedInLinks);
