const initState = {
  appliedFilters: []
};

const historyReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOAD_DATA":
      let devices = action.payload;

      return {
        ...state,
        devices,
        filteredProducts: devices
      };

    case "FILTER_BY_ID":
      //clone the state
      let newState = Object.assign({}, state);
      //let newState = Object.assign({}, state);
      //the value passed from our presentational component
      let id = Number(action.payload.value);
      let filteredValues = state.devices.filter(device => {
        //return any product whose name or designer contains the input box string
        
        let deviceNum = Number(device.device_id);
        return deviceNum === id;
      });
      let appliedFilters = state.appliedFilters;
      //if the value from the input box is not empty

      if (id) {
        //check if the filter already exists in the tracking array
        let index = appliedFilters.indexOf("FILTER_BY_ID");
        if (index === -1)
          //if it doesnât, add it.
          appliedFilters.push("FILTER_BY_ID");
        //change the filtered products to reflect the change
        newState.filteredProducts = filteredValues;
      } else {
        //if the value is empty, we can assume everything has been erased
        let index = appliedFilters.indexOf("FILTER_BY_ID");
        //in that case, remove the current filter
        appliedFilters.splice(index, 1);
        console.log("appliedFilters", appliedFilters);
        if (!appliedFilters.includes("FILTER_BY_ID")) {
          //if there are no filters applied, reset the products to normal.
          newState.filteredProducts = newState.devices;
        }
      }
      return newState;
    case "FILTER_BY_DATE_FROM":
      //clone the state
      let newStateDateFrom = Object.assign({}, state);
      //let newState = Object.assign({}, state);
      //the value passed from our presentational component
      let DateFrom = action.payload.value;
      let filteredValuesDateFrom = state.devices.filter(device => {
        //return any product whose name or designer contains the input box string
        console.log("new date ", Number(device.createdAt));
        let deviceDateUx = device.createdAt.seconds;
        let dateFromUx = parseInt(
          (new Date(DateFrom).getTime() / 1000).toFixed(0)
        );
        console.log("deviceDateUx", deviceDateUx);
        console.log("dateFromUx", dateFromUx);
        return deviceDateUx >= dateFromUx;
      });
      let appliedFiltersDateFrom = state.appliedFilters;
      //if the value from the input box is not empty

      if (DateFrom) {
        //check if the filter already exists in the tracking array
        let index = appliedFiltersDateFrom.indexOf("FILTER_BY_DATE_FROM");
        if (index === -1)
          //if it doesnât, add it.
          appliedFiltersDateFrom.push("FILTER_BY_DATE_FROM");
        //change the filtered products to reflect the change
        newStateDateFrom.filteredProducts = filteredValuesDateFrom;
      } else {
        //if the value is empty, we can assume everything has been erased
        let index = appliedFiltersDateFrom.indexOf("FILTER_BY_DATE_FROM");
        //in that case, remove the current filter
        appliedFiltersDateFrom.splice(index, 1);
        console.log("appliedFiltersDateFrom", appliedFiltersDateFrom);
        if (!appliedFiltersDateFrom.includes("FILTER_BY_DATE_FROM")) {
          //if there are no filters applied, reset the products to normal.
          newStateDateFrom.filteredProducts = newStateDateFrom.devices;
        }
      }
      return newStateDateFrom;
    case "FILTER_BY_DATE_TO":
      //clone the state
      let newStateDateTo = Object.assign({}, state);
      //let newState = Object.assign({}, state);
      //the value passed from our presentational component
      let DateTo = action.payload.value;
      let filteredValuesDateTo = state.devices.filter(device => {
        //return any product whose name or designer contains the input box string
        console.log("new date ", Number(device.createdAt));
        let deviceDateUx = device.createdAt.seconds;
        let dateFromUx = parseInt(
          (new Date(DateTo).getTime() / 1000).toFixed(0)
        );
        console.log("deviceDateUx", deviceDateUx);
        console.log("dateFromUx", dateFromUx);
        return deviceDateUx <= dateFromUx;
      });
      let appliedFiltersDateTo = state.appliedFilters;
      //if the value from the input box is not empty

      if (DateTo) {
        //check if the filter already exists in the tracking array
        let index = appliedFiltersDateTo.indexOf("FILTER_BY_DATE_TO");
        if (index === -1)
          //if it doesnât, add it.
          appliedFiltersDateTo.push("FILTER_BY_DATE_TO");
        //change the filtered products to reflect the change
        newStateDateTo.filteredProducts = filteredValuesDateTo;
      } else {
        //if the value is empty, we can assume everything has been erased
        let index = appliedFiltersDateTo.indexOf("FILTER_BY_DATE_TO");
        //in that case, remove the current filter
        appliedFiltersDateTo.splice(index, 1);
        console.log("appliedFiltersDateTo", appliedFiltersDateTo);
        if (!appliedFiltersDateTo.includes("FILTER_BY_DATE_TO")) {
          //if there are no filters applied, reset the products to normal.
          newStateDateTo.filteredProducts = newStateDateTo.devices;
        }
      }
      return newStateDateTo;
    /*  case "SORT_BY_SPEED":
      let sortBySpeedState = Object.assign({}, state);
      let sortedSpeedArr =
        action.payload.direction === "asc"
          ? sortAscNum(state.filteredProducts, "speed")
          : sortDescNum(state.filteredProducts, "speed");

      sortBySpeedState.filteredProducts = sortedSpeedArr;
      sortBySpeedState.appliedFilters = addFilterIfNotExists(
        "SORT_BY_SPEED",
        sortBySpeedState.appliedFilters
      );
      sortBySpeedState.appliedFilters = removeFilter(
        "SORT_BY_SPEED",
        sortBySpeedState.appliedFilters
      );
      return sortBySpeedState; */
    case "SORT_BY_SPEED":
      devices = action.payload;
      console.log("doc ",devices);
      return {
        //...state,
        //devices,
        filteredProducts: devices
      };
    case "SORT_BY_DATE":
      let sortByDateState = Object.assign({}, state);
      let sortedDateArr =
        action.payload.direction === "asc"
          ? sortAscNum(state.filteredProducts, "createdAt")
          : sortDescNum(state.filteredProducts, "createdAt");
      console.log(sortedDateArr);
      sortByDateState.filteredProducts = sortedDateArr;
      sortByDateState.appliedFilters = addFilterIfNotExists(
        "SORT_BY_DATE",
        sortByDateState.appliedFilters
      );
      sortByDateState.appliedFilters = removeFilter(
        "SORT_BY_DATE",
        sortByDateState.appliedFilters
      );
      return sortByDateState;
    case "SORT_BY_ID":
      let sortByIdState = Object.assign({}, state);
      let sortedIdArr =
        action.payload.direction === "asc"
          ? sortAscNum(state.filteredProducts, "device_id")
          : sortDescNum(state.filteredProducts, "device_id");
      console.log(sortedDateArr);
      sortByIdState.filteredProducts = sortedIdArr;
      sortByIdState.appliedFilters = addFilterIfNotExists(
        "SORT_BY_ID",
        sortByIdState.appliedFilters
      );
      sortByIdState.appliedFilters = removeFilter(
        "SORT_BY_ID",
        sortByIdState.appliedFilters
      );
      return sortByIdState;
    default:
      return state;
  }
};

export default historyReducer;

function sortAsc(arr, field) {
  return arr.sort(function(a, b) {
    if (a[field] > b[field]) return 1;

    if (b[field] > a[field]) return -1;

    return 0;
  });
}

function sortDesc(arr, field) {
  return arr.sort(function(a, b) {
    if (a[field] > b[field]) return -1;

    if (b[field] > a[field]) return 1;

    return 0;
  });
}
function sortAscNum(arr, field) {
  return arr.sort(function(a, b) {
    let aNum = Number(a[field]);
    let bNum = Number(b[field]);

    if (aNum > bNum) return 1;

    if (bNum > aNum) return -1;

    return 0;
  });
}

function sortDescNum(arr, field) {
  return arr.sort(function(a, b) {
    let aNum = Number(a[field]);
    let bNum = Number(b[field]);
    if (aNum > bNum) return -1;

    if (bNum > aNum) return 1;

    return 0;
  });
}

function addFilterIfNotExists(filter, appliedFilters) {
  let index = appliedFilters.indexOf(filter);
  if (index === -1) appliedFilters.push(filter);

  return appliedFilters;
}

function removeFilter(filter, appliedFilters) {
  let index = appliedFilters.indexOf(filter);
  appliedFilters.splice(index, 1);
  return appliedFilters;
}
