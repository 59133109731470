import React, { Component } from "react";

//import M from "materialize-css";
//import Modal from "./Modal";

//import { Modal, Button } from "react-materialize";

class Search extends Component {
  handleSubmit = e => {
    e.preventDefault();

    const { notifications, history } = this.props;
    let inputId = e.target.search.value;
    let found = notifications.find(
      element => Number(element.device_id) === Number(inputId)
    );
    if (found) {
      history.push("/score/" + inputId);
    } else {
      alert("device id " + inputId + " not found");
      e.target.reset();
      //console.log("modal: ", Modal);
    }
  };

  render() {
    return (
      <div className="row">
        <form className="col s12 nopadding" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="input-field col s6">
              <input id="search" type="search" className="validate" />
              <label className="white-text" htmlFor="search">
                search a device
              </label>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Search;
