export const loadData = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    getFirestore()
      .collection("notifications")
      .get()
      /* .onSnapshot(function(querySnapshot) {
        
        let a = querySnapshot.docs.map(doc => doc.data());
        a.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));

        //console.log("doc ", a);
        dispatch({ type: "LOAD_DATA", payload: a });
      }); */
      //dispatch({ type: "LOAD_DATA", payload: a })
      .then(snapshot => {
        let a = snapshot.docs.map(doc => doc.data());
        a.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
        return a;
      })
      .then(a => {
        dispatch({ type: "LOAD_DATA", payload: a });
      })
      .catch(err => {
        dispatch({ type: "CREATE_SCORE_ERROR", err });
      });
  };
};

export const filterById = payload => ({
  type: "FILTER_BY_ID",
  payload
});
export const filterByDateFrom = payload => ({
  type: "FILTER_BY_DATE_FROM",
  payload
});
export const filterByDateTo = payload => ({
  type: "FILTER_BY_DATE_TO",
  payload
});
/* export const sortBySpeed = payload => ({
  type: "SORT_BY_SPEED",
  payload
}); */
export const sortBySpeed = direction => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    getFirestore()
      .collection("notifications")
      //.get()
      .onSnapshot(function(querySnapshot) {
        //var cities = [];
        // querySnapshot.forEach(function(doc) {
        // cities.push(doc.data().name);
        let a = querySnapshot.docs.map(doc => doc.data());
        console.log("direction", direction.direction);
        if (direction.direction === "asc") {
          a.sort((a, b) => Number(a.speed) - Number(b.speed));
        } else if(direction.direction === "desc") {
          a.sort((a, b) => Number(b.speed) - Number(a.speed));
        }

        //console.log("doc ", a["speed"]);
        dispatch({ type: "SORT_BY_SPEED", payload: a });
      });
    //dispatch({ type: "LOAD_DATA", payload: a })
    // .then(snapshot => {
    //   let a = snapshot.docs.map(doc => doc.data());

    //   return a;
    // })
    // .then(a => {
    //   dispatch({ type: "LOAD_DATA", payload: a });
    // })
    // .catch(err => {
    //   dispatch({ type: "CREATE_SCORE_ERROR", err });
    //});
  };
};
export const sortByDate = payload => ({
  type: "SORT_BY_DATE",
  payload
});
export const sortById = payload => ({
  type: "SORT_BY_ID",
  payload
});
