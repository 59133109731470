import React from "react";
import moment from "moment";

const LatestResults = props => {
  const { notifications } = props;
  //let myArray = notifications && notifications.concat();
  //let sortedActivities = null;
  // const a = JSON.parse(JSON.stringify(notifications));
  // console.log("a", a);
  let myArray = notifications && notifications.map(item => item);
  console.log("myArray", myArray);
  const mySortedActivities =
    myArray &&
    myArray.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
  var nbrLatest = 6;
  const sortedActivities =
    mySortedActivities && mySortedActivities.slice(0, nbrLatest);
  // myArray &&
  // Object.values(myArray)
  //   .slice()
  //   .sort(function(c, d) {
  //     return d["createdAt.seconds"] - c["createdA.seconds"];
  //   });
  return (
    <div className="section">
      <div className="card z-depth-2 speednavgrey">
        <div className="card-content">
          <h6 className="card-title center-align orange-text">
            Last {nbrLatest}'s
          </h6>
          <ul className="notification">
            {sortedActivities &&
              sortedActivities.map(item => (
                <li key={item.id}>
                  {console.log("item", item)}
                  <span className="white-text">{item.speed} km/h</span>
                  <div className="white-text note-date">
                    <span>Device Id: {item.device_id}</span>
                  </div>

                  <div className="orange-text darken-4 note-date">
                    {moment(item.createdAt.toDate()).format("LLL")}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LatestResults;
