import React, { Component } from "react";
import { Link } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { connect } from "react-redux";
import speedshooting_logo from "./../../images/speedshooting_logo.png";
//import { NavLink } from "react-router-dom";
//import MobileNav from "./MobileNav";
//import { render } from "react-dom";

class Navbar extends Component {
  render() {
    const { auth, profile } = this.props;

    const links = auth.uid ? (
      <SignedInLinks profile={profile} />
    ) : (
      <SignedOutLinks />
    );
    const handleSignOut = () => {
      const { firebase } = this.props;
      this.props.signOut(firebase);
    };
    //const mNav = auth.uid ? <MobileNav /> : null;
    //console.log(auth);
    return (
      <>
        <nav className="nav-wrapper">
          <div className="container">
            <Link to="/" className="brand-logo.left">
              <img src={speedshooting_logo} alt="speedshooting" />
            </Link>
            {auth.isLoaded && links}
          </div>
        </nav>
      </>
    );
  }
}
const mapStateToProps = state => {
  //console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Navbar);
