import React, { Component } from "react";
import DeviceHighScores from "./DeviceHighScores";
import DeviceScoreList from "../deviceScores/DeviceScoreList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect, Link } from "react-router-dom";

class DeviceDashboard extends Component {
  render() {
    const { speed_devices, auth, notifications, currentId } = this.props;

    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="dashboard container">
        <div className="container center-align white-text text-darken-4">
          <h1>DEVICE ID: {currentId}</h1>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <DeviceScoreList
              speed_devices={speed_devices}
              currentId={currentId}
            />
          </div>
          <div className="col s12 m5 offset-m1">
            <DeviceHighScores
              notifications={notifications}
              currentId={currentId}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentId: ownProps.match.params.id,
    speed_devices: state.firestore.ordered.speed_devices,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [
    { collection: "speed_devices", orderBy: ["createdAt", "desc"] },
    { collection: "notifications", orderBy: ["speed", "desc"] }
  ])
)(DeviceDashboard);
