

export const createScore =(score) => {
    return (dispatch, getState, { getFirestore, getFirebase }) => {
      //make async call to database
      const firestore =getFirestore();
       const { profile } = getState().firebase;
       const authorId = getState().firebase.auth.uid;
      firestore
        .collection("scores") //Change to speed_devices?
        .add({
          ...score,
          authorFirstName: profile.firstName,
          authorLastName: profile.lastName,
          authorId,
          createdAt: new Date(),
        })
        .then(() => {
          dispatch({ type: "CREATE_SCORE", score: score });
        })
        .catch((err) => {
          dispatch({ type: "CREATE_SCORE_ERROR", err });
        });
      
    };
}

export const filterByDeviceId = payload => ({
  type: 'FILTER_BY_DEVICE_ID',
  payload
});
