export const loadDataDevice = currentId => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    getFirestore()
      .collection("notifications")
      .where("device_id", "==", currentId.value)
      .get()
      .then(snapshot => {
        let a = snapshot.docs.map(doc => doc.data());

        return a;
      })
      .then(a => {
        dispatch({ type: "LOAD_DATA_DEVICE", payload: a });
      })
      .catch(err => {
        dispatch({ type: "CREATE_SCORE_ERROR", err });
      });
  };
};
export const filterByDateFromDevice = payload => ({
  type: "FILTER_BY_DATE_FROM_DEVICE",
  payload
});
export const filterByDateToDevice = payload => ({
  type: "FILTER_BY_DATE_TO_DEVICE",
  payload
});

export const sortBySpeedDevice = payload => ({
  type: "SORT_BY_SPEED_DEVICE",
  payload
});
export const sortByDateDevice = payload => ({
  type: "SORT_BY_DATE_DEVICE",
  payload
});
