import React, { Component } from "react";
import LatestResults from "./LatestResults";
import ScoreList from "../scores/ScoreList";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import HighScores from "././HighScores";
import Search from "../layout/Search";
import HistoryScoreList from "../scores/HistoryScoreList";

class Dashboard extends Component {
  render() {
    const { speed_devices, auth, notifications, history } = this.props;
    console.log("notifications", notifications);
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="dashboard container">
        <div className="row">
          <div className="col s12 m6 l6">
            <HistoryScoreList />
          </div>
          <div className="col s6 m3 l3">
            <HighScores notifications={notifications} />
          </div>
          <div className="col s6 m3 l3">
            <LatestResults notifications={notifications} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    speed_devices: state.firestore.ordered.speed_devices,
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    state
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [
    { collection: "speed_devices" },
    { collection: "notifications" }
  ])
)(Dashboard);
