import authReducer from "./authReducer";
import historyReducer from "./historyReducer";
import scoreReducer from "./scoreReducer";
import deviceReducer from "./deviceReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer,
  score: scoreReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  historyData: historyReducer,
  deviceData: deviceReducer
});
export default rootReducer;
