import React, { Component } from "react";
import { loadDataDevice } from "../../store/actions/deviceActions";
import { filterByDateFromDevice } from "../../store/actions/deviceActions";
import { filterByDateToDevice } from "../../store/actions/deviceActions";
import { sortBySpeedDevice } from "../../store/actions/deviceActions";
import { sortByDateDevice } from "../../store/actions/deviceActions";
import DeviceScoreSummary from "./DeviceScoreSummary";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";

import { compose } from "redux";

class DeviceScoreList extends Component {
  // const { speed_devices } = props;
  //const { currentId } = this.props;
  // let currentDevice = null;

  // for (let i = 0; i < speed_devices.length; i++) {
  //   if (speed_devices[i]["id"] === currentId) {
  //     currentDevice = Object.keys(speed_devices)[i];
  //     break;
  //   }
  // }
  // const cDeviceData = speed_devices[currentDevice].data;

  // const sortedDeviceData = Object.values(cDeviceData).sort(function(c, d) {
  //   return d - c;
  // });
  constructor(props) {
    super(props);
    //let { notifications } = props;

    this.startDate = React.createRef();
    this.endDate = React.createRef();
  }
  componentDidMount() {
    const { currentId } = this.props;
    let M = window.M;
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    console.log("this.props", this.props);
    this.props.dispatch(loadDataDevice({ value: currentId }));
    var datepicker = document.querySelectorAll(".datepicker");
    M.Datepicker.init(datepicker, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
      onClose: () =>
        this.props.dispatch(
          filterByDateFromDevice({ value: this.startDate.current.value })
        )
    });
    var datepicker2 = document.querySelectorAll(".datepicker2");
    M.Datepicker.init(datepicker2, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
      onClose: () =>
        this.props.dispatch(
          filterByDateToDevice({ value: this.endDate.current.value })
        )
    });
  }
  sortByInput(e) {
    let value = e.target.value;
    let direction = value.endsWith("asc") ? "asc" : "desc";
    if (value.startsWith("speed")) {
      this.props.dispatch(sortBySpeedDevice({ direction }));
    } else if (value.startsWith("date")) {
      this.props.dispatch(sortByDateDevice({ direction }));
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    console.log("this.props", this.props);
    const { notifications, history } = this.props;
    console.log(this.props.state);
    let inputId = e.target.id.value;
    let found = notifications.find(
      element => Number(element.device_id) === Number(inputId)
    );
    if (found) {
      history.push("/score/" + inputId);
      e.target.reset();
    } else {
      alert("device id " + inputId + " not found");
      e.target.reset();
      //console.log("modal: ", Modal);
    }
  };

  render() {
    let devices = this.props.state.deviceData.filteredProducts;
    const { currentId } = this.props;
    return (
      <>
        <div className="row">
          <form className="col s12">
            <div className="row">
              <div className="container center-align">
                <Link to={"/score/" + currentId + "/fullscore"} key={currentId}>
                  <a className="btn-large backgroundcolor">
                    <i class="material-icons">aspect_ratio</i>
                  </a>
                </Link>
              </div>
              <div className="input-field col s3">
                <input
                  type="text"
                  className="datepicker"
                  ref={this.startDate}
                />
                <label htmlFor="from">From Date</label>
              </div>
              <div className="input-field col s3">
                <input type="text" className="datepicker2" ref={this.endDate} />
                <label htmlFor="To">To Date</label>
              </div>

              <div className="input-field col s3 black-text">
                <select
                  onChange={e => {
                    this.sortByInput(e);
                  }}
                >
                  <option value="" disabled selected>
                    Sort By
                  </option>
                  <option value="speed_desc">Speed High to Low</option>
                  <option value="speed_asc">Speed Low to High</option>
                  <option value="date_desc">Date Sooner First</option>
                  <option value="date_asc">Date Latest First</option>
                </select>
              </div>
              <div className="row">
            <form   className="nopadding" onSubmit={this.handleSubmit}>
              <div className="input-field col s3 left-align">
                <input type="text" id="id" className="validate" />
                <label htmlFor="To">Go to device id</label>
              </div>
            </form>
          </div>
            </div>

          </form>
          
        </div>

        <div className="score-list section">
          {devices &&
            devices.length &&
            devices.map(device => (
              <DeviceScoreSummary device={device} key={device.id} />
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    state
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [
    { collection: "speed_devices" },
    { collection: "notifications" }
  ])
)(withRouter(DeviceScoreList));
