import React, { Component } from "react";
import { loadData } from "../../store/actions/historyActions";
import { filterById } from "../../store/actions/historyActions";
import { sortBySpeed } from "../../store/actions/historyActions";
import { sortByDate } from "../../store/actions/historyActions";
import { sortById } from "../../store/actions/historyActions";
import { filterByDateFrom } from "../../store/actions/historyActions";
import { filterByDateTo } from "../../store/actions/historyActions";
import HistoryScoreSummary from "./HistoryScoreSummary";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { render } from "react-dom";
import { filterByDeviceId } from "../../store/actions/scoreActions";
import { useFirebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { Redirect } from "react-router-dom";

class HistoryScoreList extends Component {
  constructor(props) {
    super(props);
    //let { notifications } = props;

    this.startDate = React.createRef();
    this.endDate = React.createRef();
  }

  componentDidMount() {
    var context = this;
    this.props.dispatch(loadData({}));
    console.log("this.props", this.props);

    let options = {
      onSelect: console.log("inputme", this.startDate.current.value)
    };

    let M = window.M;
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var datepicker = document.querySelectorAll(".datepicker");
    M.Datepicker.init(datepicker, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
      onClose: () =>
        this.props.dispatch(
          filterByDateFrom({ value: this.startDate.current.value })
        )
    });
    var datepicker2 = document.querySelectorAll(".datepicker2");
    M.Datepicker.init(datepicker2, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
      onClose: () =>
        this.props.dispatch(
          filterByDateTo({ value: this.endDate.current.value })
        )
    });
  }
  filterByInput = e => {
    let input = e.target.value;
    
    this.props.dispatch(filterById({ value: input }));
  };

  //console.log(state)
  handleSubmit = e => {
    e.preventDefault();
  };
  sortByInput(e) {
    let devices = this.props.state.historyData.filteredProducts;
    let value = e.target.value;
    let direction = value.endsWith("asc") ? "asc" : "desc";
    /* if (value.startsWith("speed")) {
      direction === "desc"
          ? this.setState({
            devices: devices.sort((a, b) => Number(b.speed) - Number(a.speed),()=>console.log("deviceee",devices))})
          : this.setState({
            devices: devices.sort((a, b) => Number(a.speed) - Number(b.speed),()=>console.log("deviceee",devices))})
          console.log("deviceee",devices)
      
    } */
     if (value.startsWith("speed")) {
      this.props.dispatch(sortBySpeed({ direction }));
    /* } else if (value.startsWith("date")) {
      this.props.dispatch(sortByDate({ direction }));
    } else if (value.startsWith("id")) {
      this.props.dispatch(sortById({ direction }));
    } */
  }
  }
  // filterByDateFrom(e) {
  //   //let input = e.target.value;
  //   console.log("e", e);
  //   console.log("input", this.startDate);
  // }
  render() {
    //let { notifications } = this.props;
    //this.props.dispatch(loadData({}));
    let devices = this.props.state.historyData.filteredProducts;

    return (
      <>
        <div className="row">
          <form className="col s12">
            <div className="row">
              <div className="input-field col s6">
                <input
                  id="inputId"
                  type="text"
                  className="validate"
                  onChange={
                    //call this method on every change in input
                    this.filterByInput
                  }
                />
                <label htmlFor="inputId">Filter a device id</label>
              </div>
              <div className="input-field col s3">
                <input
                  type="text"
                  className="datepicker"
                  ref={this.startDate}
                />
                <label htmlFor="from">From Date</label>
              </div>
              <div className="input-field col s3">
                <input type="text" className="datepicker2" ref={this.endDate} />
                <label htmlFor="To">To Date</label>
              </div>
              <div className="input-field col s6" />
              <div className="input-field col s12">
                <select
                  onChange={e => {
                    this.sortByInput(e);
                  }}
                >
                  <option value="" disabled selected>
                    Sort By
                  </option>
                  <option value="speed_desc">Speed High to Low</option>
                  <option value="speed_asc">Speed Low to High</option>
                  <option value="date_desc">Date Sooner First</option>
                  <option value="date_asc">Date Latest First</option>
                  <option value="id_desc">Device Id High to Low</option>
                  <option value="id_asc">Device Id Low to High</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        
        <div className="score-list section">
          {devices &&
            devices.length &&
            devices.map(device => (
              <HistoryScoreSummary device={device} key={device.id} />
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    state
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [{ collection: "notifications" }])
)(HistoryScoreList);
