import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

class ScoreFullScreen extends Component {
  render() {
    const { speed_devices, currentId, auth } = this.props;
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }
    let currentDeviceIndex = null;
    for (let i = 0; i < speed_devices.length; i++) {
      if (speed_devices[i]["id"] === currentId) {
        currentDeviceIndex = Object.keys(speed_devices)[i];
        break;
      }
    }
    const currentDevice = speed_devices[currentDeviceIndex];

    return (
      <div className=" z-depth-0 speednavgrey center-align">
        <div className="col">
          <span className="white-text">device id: {currentId} </span>
          <h1 className="fscreen red-text">{currentDevice.speed_results.speed}</h1>
          <span className="white-text">km/h</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentId: ownProps.match.params.id,
    speed_devices: state.firestore.ordered.speed_devices,
    auth: state.firebase.auth
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, store) => [{ collection: "speed_devices" }])
)(ScoreFullScreen);
