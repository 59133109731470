import React, { Component } from "react";
import { connect } from "react-redux";
import { createScore } from "../../store/actions/scoreActions";
import { Redirect } from "react-router-dom";

class CreateScore extends Component {
  state = {
    device_id: "",
    speed: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state);
    this.props.createScore(this.state);
    this.props.history.push("/");
  };
  render() {
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit} className="white">
          <h5 className="grey-text text-darken-3">Add a Score</h5>
          <div className="input-field">
            <label htmlFor="device_id_content">Device Id</label>
            <input type="text" id="device_id" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="speed_content">Speed</label>
            <textarea
              id="speed"
              className="materialize-textarea"
              onChange={this.handleChange}
            />
          </div>
          <button className="btn orange darken-4 z-depth-0">Add</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => {
  return {
    createScore: score => dispatch(createScore(score))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateScore);
