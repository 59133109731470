import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//import M from "materialize-css";
//import "materialize-css/dist/css/materialize.min.css";

class AllDropDown extends Component {
  componentDidMount() {
    const M = window.M;

    //M.FormSelect.init(this.select, {});

    var elems = document.querySelectorAll(".dropdown-trigger");
    //var instances = M.Dropdown.init(elems, {});
  }

  render() {
    return (
      <>
        <a
          className="dropdown-trigger btn-nav"
          href="#"
          data-target="dropdown1"
        >
          Alltimes
        </a>

        <ul id="dropdown1" className="dropdown-content">
          <li>
            <NavLink to="/allhighscores">High Scores</NavLink>
          </li>
          <li>
            <NavLink to="/allresults">Last Results</NavLink>
          </li>
        </ul>
      </>
    );
  }
}

export default AllDropDown;
